.preview-tree-container{
    background-color: #000;
}

#ped {
    position: relative;
  }
  
#ped::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.preview-tree-modal{
  position: relative;
  background-color: white;
  width: 130%;
  margin-left: -15%;
}

.button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 5%;
  background-color: white;
  z-index: 0;
  background-color: #000;
}

#pedigree_history {
  position: relative;
  z-index: 0;
}

.preview-tree-modal-header{
  display: flex;
  justify-content: space-between;
}

.cross-btn{
  background-color: #fff !important;
  border:  none !important;
  color: #000 !important;
  font-size: 1.2rem;
  font-weight: 500;
}

.cross-btn:hover{
  background-color: #fff !important;
  border:  none !important;
  color: #000 !important;
}

.preview-tree-modal-header .modal-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.modal-title-centered {
  flex: 1;
  text-align: center;
}