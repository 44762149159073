.modal-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-btn{
    display: flex;
    justify-content: space-between;
}
.list-div ul li{
    margin-bottom: 20px;
    text-align: justify;
}
.list-div{
    padding: 20px;
}
