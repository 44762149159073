.chat-form {
    color: #000;
    padding: 0px 20px;
}

.chat-form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.chat-form-width{
    width: 600px;
}

.chat-form-heading {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    width: 100%;
    align-items: center;
}

.chat-form-subheading{
    font-weight: 700;
}

.check-icon {
    margin-right: 20px;
}

.element-spacing {
    padding: 10px 0;
}

.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 0 0 20px;
    width: max-content;
    font-weight: bold !important;
    color: #FD6262;
    font-size: 1rem;
}

.chat-form-input {
    height: 42px;
    padding: 12px;
    border: none;
    border-radius: 4px !important;
}

/* name field */
.chat-form-name-field {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
}

/* gender field */
.chat-form-gender-field{
    display: flex;
    gap: 85px;
}

/* date field */
.chat-form-dob-field {
    display: flex;
    flex-direction: column;
    height: 90px;
}

.dob-or-age-container {
    display: flex;
    justify-content: space-between;
}

.dob-field,
.age-field {
    flex: 1;
}

.status-group {
    flex: 1;
    display: flex;
    gap: 50px;
}

.or-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.or-divider span {
    background: transparent;
    padding: 0 10px;
}

.date-of-death-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date-of-death-input {
    width: 150px;
}

/* common parent input */
.common-parent-input{
    display: flex;
    gap: 24px;
}

/* alive deceased status field */
.chat-from-status-field {
    display: flex;
    justify-content: space-between;
    gap: 38px;
}

/* additional info field */
.additional-info-field {
    padding: 12px;
    border: none;
    border-radius: 4px !important;
    padding: 10px;
}

/* sibling relation input */
.sibling-relation-input{
    display: flex;
    gap: 44px;
}

/* custom radio button */
.custom-radio-button {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    margin-right: 20px;
    padding: 10px 0 10px 25px;
}

.custom-radio-button input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid rgba(150, 149, 152, 1);
    transition: background-color 0.2s;
}

.custom-radio-button:hover input~.checkmark {
    background-color: #ccc;
}

.custom-radio-button input:disabled ~ .checkmark {
    background-color: transparent;
}

.custom-radio-button input:checked~.checkmark {
    background-color: rgba(71, 106, 43, 1);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-radio-button input:checked~.checkmark:after {
    display: block;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* collapsible section */
.collapsible-section {
    padding: 20px;
}

/* submit button */
.form-submit-btn {
    border-radius: 4px !important;
    padding: 10px 32px;
    background-color: #FD6262 !important;
    color: #fff !important;
    font-size: 16px;
}

.continue-btn{
    margin-top: 20px;
}

.form-submit-btn:hover{
    background-color: #fff !important;
    border: 1px solid #FD6262 !important;
    color: #FD6262 !important;
}

.form-label {
    margin-bottom: 0 !important;
}

.chat-form-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}

.continue-skip-btn{
    margin-top: 20px;
    display: flex;
    align-items: center; 
    gap: 5px; 
}

.delete-button{
    background-color: transparent;
    padding: 0;
}

.add-sibling-btn{
    width: 180px;
    background-color: white !important;
    color: rgba(235, 108, 103, 1) !important;
    border: 1px solid rgba(235, 108, 103, 1) !important;
    border-radius: 4px !important;
    padding: 10px 32px !important;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.add-sibling-btn:hover{
    background-color: #FD6262 !important;
    color: #fff !important;
    border: 1px solid #FD6262 !important;
}

.form-skip-btn{
    padding: 10px 32px;
    font-size: 16px;
    border-radius: 4px;
    background-color: white !important;
    color: black !important;
    border: 1px solid black !important;
    border-radius: 4px !important;
    margin-left: 10px;
}

.form-skip-btn:hover{
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}

.children-section{
    padding-top: 20px;
}

.marriage-collapsible-section{
    padding: 20px 0px 0px 0px;
}

hr{
    margin: 0;
}

.chat-form-component-spacing{
    padding: 10px 0px;
}

.child-form-header{
    display: flex;
    justify-content: space-between;
}

.pregnancy-loss-input{
    display: flex;
    justify-content: space-between;
}

.pregnancy-loss-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-page-modal-btn{
    display: flex;
}

.new-chat-btn{
    border-radius: 4px !important;
    font-size: 16px !important;
    margin-right: 10px !important;
}

.existing-chat-btn{
    border-radius: 4px !important;
    font-size: 16px !important;
    margin-left: 10px !important;
}

.error-message{
    color: #FD6262;
}

.resume-btn{
    border-radius: 4px !important;
    background-color: #fa7a7a !important;
}

.skip-icon{
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.skipped-form-header{
    display: flex;
    justify-content: space-between;
}

.skipped-form-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 700;
}

.chat-form-label{
    font-size: 16px;
} 

.name-input-field{
    width: 100%;
}

.chat-form-container{
    width: 600px;
}

.collapsed-chat-form-container{
    width: 30vw;
}

.minimize-button-section{
    display: flex;
}

.mandatory-form-text{
    color: #FD6262;
}

.child-form-collapse-section{
    padding: 20px 0px;
}

.date-of-death-group{
    display: flex;
    flex-direction: row;
}

.dod-field,
.death-age-field {
    flex: 1;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.update-cancel-buttons {
    display: flex;
    align-items: center; 
    gap: 10px; 
} 

.no-choice-icon{
    height: 25px;
    margin-right: 20px;
}

.add-btn{
    border-radius: 4px !important;
}

.other-parent-name-input{
    flex: 1 1;
}

.checkbox-input{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
}

.cancel-btn{
    border-radius: 4px !important;
    background-color: transparent !important;
    color: #FD6262 !important; 
}

.cancel-btn:hover{
    background-color: #ffff !important;
    border: 1px solid #FD6262 !important;
    color: #FD6262 !important;
}

.add-child-btn{
    border-radius: 4px !important;
    background-color: #fff !important;
    border: 1px solid #FD6262 !important;
    color: #FD6262 !important;
    font-weight: 700;
}

.add-child-btn:hover{
    background-color: #FD6262 !important;
    border: 1px solid #FD6262 !important;
    color: #fff !important;
}
