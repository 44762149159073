.client-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.client-page-tabs {
  margin: 5% 0 0 1%;
}

.patient-list-container {
  margin: 0 1%;
  padding: 20px;
  background: hsla(0,0%,100%,.1);
  border-top-right-radius: 10px 10px;
  border-top-left-radius: 10px 10px;
}

.add-user-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1% 0 2% 0;
}

.file-upload-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.file-upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 1%;
}

.add-user-icon {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.add-user-icon:hover {
  cursor: pointer;
}

th {
  text-align: center;
}

td {
  text-align: center;
}

.file-input-label{
  color: #fff;
}

.patient-data-list .nav-tabs{
  margin: 5% 1% 1% 1% !important;
}

.send-mail-btn{
  border: 2px solid #fd6262 !important;
  background-color: transparent !important;
  color: #fff !important;
  width: 9rem;
}

.send-mail-btn:hover{
  background-color: #fd6262 !important;
  border: 2px solid #fd6262 !important;
}

.upload-button{
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-btn{
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-btn i{
  color: #fff;
}


@media (max-width: 768px) {
  .client-page {
    overflow: hidden;
  }

  .file-upload-input {
    flex-direction: column;
    width: 60%;
  }

  .file-upload-btn {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5%;
  }

  .add-user-icon {
    font-size: 10px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
