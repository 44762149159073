.tree-page{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 5%;
}
/* hiding fullscreen options */
#history_ex1 {
  display: none;
}
.tree-container {
  height: 100%;
  width: 100%;
}
.email-option{
  margin: 1%;
}
.input-container {
  display: flex;
  justify-content: center;
}
.checkbox-container label {
  display: block;
  margin-bottom: 10px;
}
.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}
.checkbox-option {
  margin: 1%;
  display: flex;
  flex-direction: column;
  display: none;
}
.pdf-download-btn{
  background: transparent;
  border: 2px solid #fd6262 ;
  border-radius: 5px;
  height: 38px !important;
  display: flex;
  align-items: center;
}
.pdf-download-btn:hover{
  border: 2px solid #fd6262 ;
  background-color: #fd6262;
  transition: all 0.5s ease-out;
}
.email-box{
  display: flex;
}
.pdf-btn{
  margin-left: 1%;
}
.note-text{
  text-align: center;
  color: #7e7a86;
  margin-top: 13px;
}
.download-icon{
  color: #fff;
}
/* Your CSS file or style block */
#ped rect {
  fill:#fff !important; 
}

#ped {
  position: relative;
}

#ped::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.navbar-user-name {
  color: #fff;
  margin-top: 13px;
  justify-content:center ;
  padding: 10px;
  position: relative;
  top: 65px;
  z-index: 10;
}

.send-button{
  height: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  color: #fff !important;
  background-color: transparent !important;
  border: 2px solid #fd6262 !important ;
  border-radius: 5px !important;
}

.send-button:hover{
  border: 2px solid #fd6262 !important;
  background-color: #fd6262 !important;
} 

#pedigree_history span i{
  color: #fff;
  margin: 5px;
  cursor: pointer;
  background-color: transparent;
}

#node_properties td {
  color: black !important;
}

@media (max-width:768px) {
  .tree-page{
    margin-top: 12%;
  }
  .input-container{
    margin: auto;
  }
  .navbar-user-name{
    position: relative;
    top: 95px ;
  }
  .pdf-btn{
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }
  .options-container{
    height: auto;
  }
  .email-option{
    width: auto;
  }
  .email-options input{
    width: 50%;
  }
  .send-btn{
    display: flex;
    align-items: center;
    margin: auto;
  }
  #pedigree_history{
    margin-top: 5%;
  }
}
