/* Fallback.css */

.fallback-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.fallback-heading {
  font-size: 2rem;
  color: #fd6262;
}

.fallback-content {
  font-size: 1rem;
  color: #555;
}

.fallback-container a{
  color: #fd6262;
}
