.register-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.register-signup-page-heading {
  text-align: center;
  color: white;
  font-size: 80px;
}
.register-otp-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  color: #ffffff;
}
.register-otp-form-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.register-otp-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.register-hide-form{
  display: none;
  margin: 2% 10%;
}

.register-show-form{
  display: block;
  margin: 2% 10%;
  }

.register-success-message {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  font-size: 26px;
  color: #fff;
}

.register-otp-timer{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: 2%;
}

@media (max-width: 768px) {
  .register-page {
    overflow: hidden;
  }
  .register-otp-form-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }
}
