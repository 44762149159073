.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.home-page-container{
    width: 80%;
    margin: 5%;
}

.homepage-login-show {
    display: block;
}

.homepage-login-hide {
    display: none;
    margin: 0 3%;
}

.home-login {
    width: 100%;
    min-height: 100vh;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
}

.home-otp-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}

.home-otp-form-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
}

.home-otp-timer{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-top: 2%;
}

.home-login-container {
    width: 100%;
}

.home-otp-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
}

.home-page-heading {
    text-align: center;
    margin-bottom: 5%;
    color:#ffffff;
}

.h-custom {
    height: calc(100% - 73px);
    text-align: justify;
}

.login-tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
    color: #e4595a !important;
    border: 1px solid #fff;
    margin-right: 5px;
    border-radius: 0px !important;
}

.otp-input-field{
    border-radius: 0px;
}

@media (max-width: 992px) {
    .h-custom {
        height: 100%;
    }
    .home-login {
        overflow: hidden;
    }

    .home-otp-form-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1%;
    }
    .home-page-container{
        width: 100%;
    }
}

#user-role-tabs{
    flex-wrap: nowrap !important;
}

@media (min-width:1200px){
    .img-fluid{
        margin-right: 9rem !important;
    }
}

/* Custom styles for the specific Form.Group with the passkey input */
.custom-passkey-group .form-control.is-invalid,
.custom-passkey-group .was-validated .form-control:invalid {
  border-color: #dc3545; /* Red border color */
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
}

/* Custom styles for the feedback message */
.custom-passkey-group .form-control.is-invalid + .form-control-feedback,
.custom-passkey-group .was-validated .form-control:invalid + .form-control-feedback {
  color: #dc3545; /* Red color for the feedback message */
  font-size: 16px; /* Adjust the font size as needed */
}

.register-link{
    display: inline-flex;
    
        margin: 8px auto;
        justify-content: center;
        align-items: center;
}

.register-link > *{
    color: #fd6262;
}

