.doctor-list-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.data-list .nav-tabs{
  margin: 5% 1% 1% 1%;
}

.list-tabs{
  margin: 5% 0 0 1%;
}

@media (max-width: 768px) {
  .doctor-page {
    overflow: hidden;
  }
  .list-tabs{
    margin-top: 15%;
  }
}
