.back-btn {
    position: absolute; 
    top: 1%;             
    left: 1%;         
    background: #fd6262; 
    border: none;       
    color: #000000;    
    z-index: 50;         
    border-radius: 0; 
}

.back-btn-container {
    display: flex;
    flex-direction: row-reverse;
}

.back-arrow-btn{
    top: 1%;
    left: 1%; 
    display: none;
    border: none;
    position: absolute;
    background: #fd6262;
    color: #000000;
    z-index: 50;
  }

@media screen and (max-width: 992px) {
    .back-arrow-btn{
        display:block
    }
    .back-btn {
        display: none; 
    }
}

