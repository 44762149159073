.toast-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 300px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 100;
}

.btn-close {
  display: none;
}

.alert-message {
  margin: auto; 
}
.close-button {
  padding: 0.25rem 0.75rem;
}
.success-background {
  background-color: #d4edda;
}

.error-background {
  background-color: #f8d7da;
}
.success-text {
  color: #155724;
}

.error-text {
  color: #721c24;
}

@media (max-width: 768px) {
  .toast-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    max-width: 300px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .success-background {
    background-color: #d4edda;
  }
  
  .error-background {
    background-color: #f8d7da;
  }
  .success-text {
    color: #155724;
  }
  
  .error-text {
    color: #721c24;
  }
}
