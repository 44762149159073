.login-form-input{
  background: #ffffff;
  color: #000000;
  border-radius: 0px;
}

.login-form-input::placeholder{
  color: #ffffff;
}

.input-field-label{
  color: #ffffff;
}

@media (max-width: 992px) {
    .h-custom {
        height: 100%;
    }
}

/* Custom styles for the specific Form.Group with the passkey input */
.custom-passkey-group .form-control.is-invalid,
.custom-passkey-group .was-validated .form-control:invalid {
  border-color: #dc3545; /* Red border color */
  padding-right: calc(1.5em + 0.75rem);
  background-image: none;
}

/* Custom styles for the feedback message */
.custom-passkey-group .form-control.is-invalid + .form-control-feedback,
.custom-passkey-group .was-validated .form-control:invalid + .form-control-feedback {
  color: #dc3545; /* Red color for the feedback message */
  font-size: 16px; /* Adjust the font size as needed */
}
