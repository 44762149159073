.media-page-container{
  overflow: hidden;
  margin: 5% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.media-page-heading{
  color: #ffffff;
  margin-bottom: 10%;
}

.upload-media-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.upload-file-section{
  width: 100%;
  width: 371px;
  height: 258px;
  border: 1px solid #8f8f8f;;
  background: hsla(0,0%,100%,.1);
  color: #c0bcca;

}

.upload-media-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.options{
  display: flex;
  flex-direction: row;
}

.file-input{
  display: none;
}

.upload-button{
  width: 100%;
  padding: 18px !important;
  color: #fff !important;
}

.upload-media-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  width: 371px;
  
}

.btn-options{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

.btn-options-heading{
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
}

.btn-option{
  margin: 2% 1%;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.btn-option a{
  text-decoration: none;
  color: #fff;
}

.btn-option:hover{
  background-color: #fd6262;
  transition: all 0.5s ease-out;
}

.options{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
}
