.feedback-form{
    background: hsla(0,0%,100%,.1);
    width: 40%;
    margin:  0 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid rgba(236,236,236,0.2);
}

.feedback-form-heading{
    color: #fff;
    margin-bottom: 5px;
}
.feedback-form-input{
    width: 90%;
    color: #fff;
}

.send-feedback-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:992px){
    .feedback-form{
        width: 100%;
        margin: 0;
        padding: 5px;
    }
}
