.choices-btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.choices-btn Button{
    background-color: #fff !important;
    color: #000 !important;
    border: 1px #fd6262 solid !important;
    border-radius: 10px !important;
}

.choices-btn div{
    padding: 0 10px;
}

.choices-btn Button:hover{
    background-color: #fd6262 !important;
}
.selected-choice-text{
    color: #000;
    font-size: 16px;
}
