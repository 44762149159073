.login-form-show {
  display: block;
  margin: 2% 10%;
}

.login-form-hide {
  display: none;
  margin: 2% 10%;
}
.login-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.login-page-heading {
  text-align: center;
  margin-top: 5%;
  color: #ffffff;
}
.login-otp-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  color: #ffffff;
}
.login-otp-form-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}
.login-page-container {
  width: 100%;
}

.login-otp-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.login-otp-timer{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: 2%;
}

.login-input-label{
  color: #ffffff;
}

@media (max-width: 768px) {
  .login-page {
    overflow: hidden;
  }
  .login-otp-form-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
  }
}
