.doctor-table-container {
  margin: 0 1%;
  padding: 1%;
  background: hsla(0,0%,100%,.1);
  border-top-right-radius: 10px 10px;
  border-top-left-radius: 10px 10px;
}

.table-btn{
  border: 2px solid #fd6262 !important;
  background-color: transparent !important;
  color: #fff !important;
  width: 9rem;
}

.table-btn:hover{
  background-color: #fd6262 !important;
  border: 2px solid #fd6262 !important;
}

th {
  text-align: center;
}

td {
  text-align: center;
}
