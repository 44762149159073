*{
  font-family: 'Montserrat', sans-serif;
}
:root {
  line-height: 1.5;
  font-weight: 400;
  background-color: #17151b;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

#root {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #17151b;
}

#root>div{
  width: 100%;
  display: flex;
  justify-content: center;
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
  min-width: 320px;
  min-height: 100vh;
  max-width: 100%;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.icon-display-hidden{
  display: none ;
}

#pedigree_history {
  display: flex;
  justify-content: end;
}