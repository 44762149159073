.loader-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  text-align: center;
}

.loader-container-map {
  position: absolute;
}

.loader-container-state-tab {
  position: relative;
}

.loader {
  position: relative;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #064691;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  opacity: 0.7;
  z-index: 1;
}

.map-position {
  top: 500%;
  left: 450%;
}

.state-tab {
  top: 50%;
  left: 300%;
}

.loader-text{
  color: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
