.cs-main-container {
  width: 1441px !important;
  height: 80vh !important;
  margin-top: 20px;
  border: none !important ;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  overflow-y: auto; 
}

.relative-container {
  position: relative;
  height: 100%;
}

.centered-heading {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
}

.cs-message {
  box-sizing: border-box;
  font-size: 1em;
  font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  border-radius: 0;
}

.message-container {
  text-align: -webkit-auto;
}
.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.btn-container{
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
.btn-div{
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cs-message__content{
  background-color: #fff !important;
  color:#000 !important;
  border: 1px solid #F2F2F2 !important;
  margin-top: 10px;
}

.cs-message--outgoing .cs-message__content{
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #c0bcca !important;
  border-radius: 10px !important;
}

.cs-chat-container{
  background-color: transparent !important;
}

.cs-message-list{
  background-color: transparent !important;
  overflow-y: auto; 
}

.cs-message-input__content-editor-wrapper{
  background-color: #fff !important;
  
  border: 2px solid #312e37 !important;
}

.cs-message-input__content-editor{
  background-color: #fff !important;
  color: #000 !important;
}

.cs-message-input__content-editor::placeholder{
  color: #fff !important;
}

.cs-button--send{
  color: #000 !important;
}

.cs-message-list .cs-typing-indicator{
  background-color: transparent !important;
}

.cs-typing-indicator__text{
  color: #fd6262 !important;
}

.cs-typing-indicator__dot {
  background-color: #fd6262 !important;
}

.generate-tree-button {
  background-color: #fd6262 !important;
  color: #fff !important;
  border: 1px solid #fd6262;
  border-radius: 1px;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  width: 90px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.5s ease-out;
}

.generate-tree-button:hover {
  background-color: #000000 !important;
  color: #ffffff !important; 
  border: 1px solid #ffffff !important; 
}

.chatbox-container {
  display: flex;
  flex-direction: column-reverse;
  height: 500px;
  overflow-y: auto;
}

.cs-message:not(:only-child) {
  margin-top: 1em !important;
}

.generate-tree-preview-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding-bottom: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate-tree-btn{
  width: 30% !important;
  font-weight: 700 !important;
  border: 1px solid #fd6262 !important;
  border-top-right-radius : 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-top: 20px;
}

.generate-tree-btn:hover{
  background-color: #fd6262 !important;
  border: 1px solid #fd6262 !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.preview-tree-btn{
  width: 30% !important;
  background-color: #fff !important;
  border: 1px solid #fd6262 !important;
  color: #fd6262 !important;
  font-weight: 700 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  margin-top: 20px;
}

.tree-icon{
  margin-right: 10px;
}

.preview-tree-btn:hover{
  background-color: #fff !important;
  border: 1px solid #fd6262 !important;
  color: #fd6262 !important;
  font-weight: 700 !important;
}

@media screen and (max-width:992px){
  .btn-div{
    width: auto;
    height: 20% !important;
    margin: 0 10px;
  }
  .generate-tree-button {
    width: 50px;
    height: 35px;
    font-size: 10px; 
  }
}

@media (max-width: 1448px) {
  .cs-main-container {
    width: 90vw !important; 
    height: 80vh !important;
    overflow-y: auto;
  }
}

@media (max-width: 992px) {
  .cs-main-container {
    width: 90vw !important; 
    height: 80vh !important; 
    overflow-y: auto; 
  }
}

@media (max-width: 768px) {
  .cs-main-container {
    width: 85vw !important; 
    height: 80vh !important; 
    overflow-y: auto;
  }
}

@media (max-width: 576px) {
  .cs-main-container {
    width: 80vw !important; 
    height: 60vh !important; 
    overflow-y: auto; 
  }
}

.loader-container{
  height: 20px;
  justify-content: center;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 3px;
  background-color: #fd6262;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1s infinite ease;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.typing-animation .dot:nth-child(1) {
  animation-delay: 0s;
}

.typing-animation .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-animation .dot:nth-child(3) {
  animation-delay: 0.6s;
}

.message-wrapper .cs-message__content{
  border: 2px solid #eb6c67 !important;
}

