.super-admin-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .super-admin-page {
    overflow: hidden;
  }
}
