/* CSS Styles */
.transcript-page{
  width: 100%;
  margin: 5% 0;
}

.transcript-page-heading{
  color: #fff;
}

.upload-pdf-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-file {
  display: none;
}

.upload-pdf-button {
  margin-top: 20px;
}
.upload-btn{
  width: 100%;
  height: auto;
  color: #fff !important;
}

.generate-tree-btn{
  width: 100%;
  height: auto;
  color: #fff !important;
}

.left-section {
  border: 1px solid #8f8f8f;;
  background: hsla(0,0%,100%,.1);
  color: #c0bcca;
  margin: 1% 2%;
  width: 371px;
  height: 258px;
}

.upload-area {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.right-section {
  display: none;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  margin: 1% 2%;
}

.btn-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 371px;
}

.btn-div{
  width: 100%;
  margin-top:13px ;
}

.goto-chat-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30%;
}

.transcript-btn-options{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  height: 40%;
}

.transcript-btn-heading{
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}

.transcript-btn-option{
  margin: 2% 1%;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
}

.transcript-btn-option a{
  text-decoration: none;
  color: #fff;
}

.transcript-btn-option:hover{
  background-color: #fd6262;
  transition: all 0.5s ease-out;
}

@media screen and (max-width:992px) {

  .transcript-page{
    height: 100vh;
    margin: 30%;
  }
  .upload-pdf-section{
    display: flex;
    flex-direction: column;
  }

  .left-section{
    margin: 5%;
  }
  .right-section{
    margin: 5%;
  }

  .transcript-btn-options{
    height: 55%;
  }
}
