.family-tree-container {
  height: 100%;
  width: 100%;
  padding: 2%;
}
.json-upload-container{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-json-heading{
  color: #fff;
  margin-bottom: 10%;
}

.upload-json-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.family-tree-content {
  width: 100%;
  margin-top: 10%;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  font-size: 18px;
  color: #7e7a86;
}

.family-tree-content i{
  color: #fff;
}

.family-tree{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}

.upload-section {
  width: 100%;
  border: 1px solid #8f8f8f;;
  background: hsla(0,0%,100%,.1);
  color: #c0bcca;
  text-align: center;
  width: 371px;
  height: 258px;
}

.upload-json-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.json-upload-button{
  width: 371px;
}

.upload-json-btn{
  margin-top:1rem ;
  padding: 18px !important;
  width: 100%;
  color: #fff !important;
}

/* Add any additional CSS styles as needed */

@media (max-width: 768px) {
  .family-tree{
    overflow: hidden;
  }
  .json-upload-container{
    width: 90%;
  }
  .upload-section{
    display: flex;
    flex-direction: column;
  }
  
}
