.outlined-input-container {
    position: relative;
    margin: 10px 0;
    width: 100%;
}

.outlined-label {
    position: absolute;
    top: 50%;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    color: gray;
    font-size: 14px;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    pointer-events: none;
}

.focused .outlined-label {
    top: -1px;
    font-size: 12px;
    color: #565252;
    background: linear-gradient(to bottom, #f2f2f2 60%, white 40%);
}

.outlined-input {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(150, 149, 152, 1);
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    transition: border-color 0.2s ease;
    background-color: white;
    color: #000;
}

.outlined-input:focus {
    border-color: rgba(150, 149, 152, 1);
}

.disabled{
    background-color: #f2f2f2;
}

.outlined-label.disabled {
    background: #f2f2f2;
}

.input-error {
    width: 100%;
    padding: 10px;
    border: 1px solid #FD6262;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.2s ease;
    background-color: white;
    color: #000;
}

.react-datepicker-wrapper {
    width: 100% !important;
  }