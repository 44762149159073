.patient-list-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.patient-list-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #3498db;
  color: #fff;
}

.patient-table-container {
  margin: 5% ;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: hsla(0,0%,100%,.1) ;
}

@media (max-width: 768px) {
  .patient-page {
    overflow: hidden;
  }
}
