.free-tree-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 1%;
}
#ped svg {
  width: 100%;
}

.free-tree-container {
  height: 100%;
  width: 100%;
}
.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .free-tree-page {
    overflow: hidden;
  }
  .free-tree-container {
    margin: 5%;
    width: auto;
  }
}
