.header-container{
    background: #E5E5E5;
    padding: 10px 20px;
    border-radius: 4px;
}

.chat-form-sub-header{
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
}

.mandatory-mark{
    color: #EB6C67;
    margin-top: 10px;
    font-size: 0.9rem;
}

.transition-on-scroll {
  transition: max-height 0.8s ease;
  scroll-behavior: smooth;
  overflow: hidden;
  height: fit-content;
}

.edit-btn{
    border: 2px solid #eb6c67;
    border-radius: 10px;     
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2px;          
    background-color: #f9f9f9;
}