.logout-btn {
    position: absolute;
    top: 1%;
    right: 3%; 
    background: #fd6262;
    border: none;
    color: #000000;
    z-index: 50;
  }
  
  .logout-power-btn{
    top: 1%;
    right: 1%; 
    display: none;
    border: none;
    position: absolute;
    background: #fd6262;
    color: #000000;
    z-index: 50;
  }

  .logout-btn-container{
    display: flex;
    flex-direction: row-reverse;
  }

  @media screen and (max-width:992px){
    .logout-power-btn{
      display: block;
    }
    .logout-btn{
      display: none;
    }
  }
  